<template>
  <div class="home">
    <div class="logo">
      <img src="@/assets/ifengImg.png" alt="" />
    </div>
    <ul>
      <li>
        <img src="@/assets/img/beeResources.png" alt="" />
        <p>蜂资源</p>
      </li>
      <li>
        <img src="@/assets/img/cloudBeeCAD.png" alt="" />
        <p>云蜂CAD</p>
      </li>
      <li>
        <img src="@/assets/img/collaborativeSpace.png" alt="" />
        <p>协同空间</p>
      </li>
      <li>
        <img src="@/assets/img/learningCenter.png" alt="" />
        <p>学习中心</p>
      </li>
      <li>
        <img src="@/assets/img/personalSpace.png" alt="" />
        <p>个人空间</p>
      </li>
      <li>
        <img src="@/assets/img/zhihuaBee.png" alt="" />
        <p>智慧蜂</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "HomeView",
};
</script>
<style lang="less" scoped>
.home {
  width: 100%;
  height: 100%;
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
    img {
      width: 250px;
      height: 200px;
    }
  }
  ul {
    display: flex;
    justify-content: space-between;
    padding: 0 400px;
    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 150px;
      height: 150px;
      img {
        width: 100px;
        height: 100px;
      }
      p {
        font-weight: 700;
        font-size: 28px;
        line-height: 0;
      }
    }
  }
}
</style>
